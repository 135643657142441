<template>
  <v-row no-gutters class="my-6">
    <v-col cols="6">
      <div class="text-capitalize">Api Credentials</div>
    </v-col>
    <v-col cols="6">
      <div class="d-flex justify-center">
        <v-btn
          @click="
            regenerateCustomerApiCredentials(
              user,
              apiCredentials ? true : false
            )
          "
          class="ma-0 pa-2 flex"
          color="success"
          outlined
          small
        >
          {{ apiCredentials ? "Revoke" : "Generate" }}
        </v-btn>
      </div>
    </v-col>
    <v-col class="mt-4" v-if="apiCredentials" cols="12">
      <v-row
        class="my-4"
        no-gutters
        v-for="(value, key) in apiCredentials"
        :key="key"
      >
        <v-col class="d-flex transparent-1" cols="6">
          <div class="pa-2">
            {{ key }}
          </div>
          <v-spacer></v-spacer>
          <v-btn @click="copyText(key)" small dense icon>
            <v-icon small dense>mdi-content-copy</v-icon>
          </v-btn>
        </v-col>
        <v-col class="d-flex transparent-3" cols="6">
          <div class="pa-2">
            {{ value }}
          </div>
          <v-spacer></v-spacer>
          <v-btn @click="copyText(value)" small dense icon>
            <v-icon small dense>mdi-content-copy</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  props: {
    user: { required: true, default: null },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    apiCredentials() {
      return this.user?.customer?.api_credentials;
    },
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    copyText(value) {
      this.$helpers.copyText(value);
      this.$notify({
        group: "msg",
        title: "Copied!",
        text: value,
        type: "success",
      });
    },
    async regenerateCustomerApiCredentials(user, isRegenerate = true) {
      if (isRegenerate)
        if (!confirm("After revoking. Existing credentials will not work."))
          return false;
      if (
        !confirm(
          `Are you sure you want to ${
            isRegenerate ? "revoke" : "generate new"
          } api credentials.`
        )
      )
        return false;

      return await this.post(
        `${this.baseUrl}/regenerate-customer-api-credentials`,
        { user_id: user.id },
        true
      ).then((data) => {
        if (data == "undefined") return;
        this.showMessageFromResponseDta(data);

        if (data.code == 200) {
          this.refresh();
        }
      });
    },
  },
};
</script>
