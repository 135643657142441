<template>
  <v-row no-gutters>
    <v-col class="d-flex justify-center" cols="12">
      <v-btn
        class="flex"
        color="secondary"
        @click="assignAgentToCustomerDialog = true"
      >
        {{ !!customer.agent_id ? "Change Assigned Agent" : "Assign Agent" }}
      </v-btn>
    </v-col>

    <v-dialog
      v-if="assignAgentToCustomerDialog"
      v-model="assignAgentToCustomerDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5 transparent-3">
          Select Agent
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            icon
            @click="assignAgentToCustomerDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="my-3">
          <v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
            <v-autocomplete
              clearable
              :rules="rules"
              v-model="agent_id"
              :multiple="false"
              :items="agentsList"
              :itemText="'text'"
              :itemValue="'value'"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="transparent-2">
          <v-btn
            color="green darken-1"
            outlined
            block
            @click="assignAgentToCustomer()"
          >
            Assign Now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { required, validUrl } from "@/plugins/validationRules.js";

export default {
  components: {},
  props: {
    customer: { required: true, default: null },
  },
  data() {
    return {
      assignAgentToCustomerDialog: false,
      agent_id: null,
      isValidForm: false,
      rules: [required()],
    };
  },
  mounted() {
    this.$store.dispatch("getAgentsList");

    if (!!this.customer?.agent_id) {
      this.agent_id = this.customer?.agent_id;
    }
  },
  computed: {
    agentsList() {
      return this.$store.getters["assignableAgentListsMapped"];
    },
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    async assignAgentToCustomer() {
      this.$refs.from.validate();
      if (!this.isValidForm) return;

      let data = {
        customer_id: this.customer.id,
        agent_id: this.agent_id,
      };

      return await this.post(
        `${this.baseUrl}/assign-agent-to-customer`,
        data,
        true
      ).then((data) => {
        if (data == "undefined") return;
        this.showMessageFromResponseDta(data);

        if (data.code == 200) {
          this.assignAgentToCustomerDialog = false;
          this.refresh();
        }
      });
    },
  },
};
</script>
