<template>
  <v-row no-gutters>
    <v-col class="d-flex justify-center" cols="12">
      <v-btn
        class="flex"
        color="secondary"
        @click="changePasswordDialog = true"
      >
        Change Password
      </v-btn>
    </v-col>

    <v-dialog
      v-if="changePasswordDialog"
      v-model="changePasswordDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5 transparent-3">
          New Password
          <v-spacer></v-spacer>
          <v-btn color="error" icon @click="changePasswordDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="my-3">
          <v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
            <v-text-field
              append-icon="mdi-eye"
              v-model="newPassword"
              @click:append="showPassword = !showPassword"
              :type="showPassword ? 'text' : 'password'"
              title="New Password"
              :rules="rules"
            />
            <v-checkbox
              v-model="isLogout"
              :label="`Logout From All Devices: ${isLogout ? 'Yes' : 'No'}`"
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions class="transparent-2">
          <v-btn
            color="green darken-1"
            outlined
            block
            @click="changeUserAccountPassword()"
          >
            Save Now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { required, minLen } from "@/plugins/validationRules.js";

export default {
  components: {},
  props: {
    user: { required: true, default: null },
    hardRefreshAllowed: { required: false, default: true },
  },
  data() {
    return {
      changePasswordDialog: false,
      showPassword: false,
      newPassword: null,
      isValidForm: false,
      isLogout: false,
      rules: [required(), minLen(8)],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    async changeUserAccountPassword() {
      this.$refs.from.validate();
      if (!this.isValidForm) return;

      let data = {
        user_id: this.user.id,
        password: this.newPassword,
        is_logout: this.isLogout ? 1 : 0,
      };

      return await this.post(
        `${this.baseUrl}/change-user-account-password`,
        data,
        true
      ).then((data) => {
        if (data == "undefined") return;
        this.showMessageFromResponseDta(data);

        if (data.code == 200) {
          this.changePasswordDialog = false;
          this.refresh();
          if (this.isLogout && this.hardRefreshAllowed) {
            document.location.reload();
          }
        }
      });
    },
  },
};
</script>
