<template>
  <v-row no-gutters>
    <v-col class="d-flex justify-center" cols="12">
      <v-btn
        class="flex"
        color="secondary"
        @click="assignApplicationSourceToCustomerDialog = true"
      >
        {{
          !!customer.application_source
            ? "Change Assigned Application Source"
            : "Application Source"
        }}
      </v-btn>
    </v-col>

    <v-dialog
      v-if="assignApplicationSourceToCustomerDialog"
      v-model="assignApplicationSourceToCustomerDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5 transparent-3">
          Select Agent
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            icon
            @click="assignApplicationSourceToCustomerDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="my-3">
          <v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
            <v-autocomplete
              clearable
              :rules="rules"
              v-model="application_source"
              :multiple="false"
              :items="applicationSourcesListMapped"
              :itemText="'text'"
              :itemValue="'value'"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="transparent-2">
          <v-btn
            color="green darken-1"
            outlined
            block
            @click="assignApplicationSourceToCustomer()"
          >
            Assign Now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { required, validUrl } from "@/plugins/validationRules.js";

export default {
  components: {},
  props: {
    customer: { required: true, default: null },
  },
  data() {
    return {
      assignApplicationSourceToCustomerDialog: false,
      application_source: null,
      isValidForm: false,
      applicationSourcesList: [],
      rules: [required()],
    };
  },
  async mounted() {
    await this.getApplicationSources();

    if (!!this.customer?.payload?.application_source) {
      this.application_source = this.customer?.payload?.application_source;
    } else {
      this.application_source = "Api Partner";
    }
  },
  computed: {
    applicationSourcesListMapped() {
      return (
        this.applicationSourcesList?.map((source) => {
          let sourceAgent = source?.agents[0]?.user?.email || "";
          return {
            text: `${source.name} ${sourceAgent}`,
            value: `${source.name}`,
          };
        }) || []
      );
    },
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    async getApplicationSources() {
      let data = {};
      return await this.post(
        this.baseUrl + `/agent-portal/get-application-source-list`,
        data,
        false
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.applicationSourcesList = data?.data?.application_source_list;
          // console.log(JSON.stringify(this.applicationSourcesList));
        }
      });
    },
    async assignApplicationSourceToCustomer() {
      this.$refs.from.validate();
      if (!this.isValidForm) return;

      let data = {
        customer_id: this.customer.id,
        application_source: this.application_source,
      };

      return await this.post(
        `${this.baseUrl}/assign-application-source-to-customer`,
        data,
        true
      ).then((data) => {
        if (data == "undefined") return;
        this.showMessageFromResponseDta(data);

        if (data.code == 200) {
          this.assignApplicationSourceToCustomerDialog = false;
          this.refresh();
        }
      });
    },
  },
};
</script>
